@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8" width="100%">
    <h1 style="font-weight: 800; color: #ff0000">ข้อมูลผู้ใช้งาน</h1>
    <!-- <v-row>
      <v-col cols="2"><p style="font-weight:800">username</p></v-col>
      <v-col cols="4">
        <span>{{ username }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p style="font-weight:800">password</p></v-col>
      <v-col cols="4">
        <span>{{ password }}</span>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col v-for="(i, index) in form.profileImages" :key="index">
        <v-img :src="i.fileUrl" max-width="50px"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><p style="font-weight: 800">ชื่อ</p></v-col>
      <v-col cols="4">
        <span>{{ form.firstName }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">นามสกุล</p></v-col>
      <v-col cols="4">
        <span>{{ form.lastName }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">รหัสโทรศัพท์</p></v-col>
      <v-col cols="4">
        <span>{{ form.telCode }}</span>
      </v-col>

      <v-col cols="2"><p style="font-weight: 800">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <span>{{ form.telNumber }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">อีเมล</p></v-col>
      <v-col cols="4">
        <span>{{ form.email }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ไอดีไลน์</p></v-col>
      <v-col cols="4">
        <span>{{ form.line }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">เพศ</p></v-col>
      <v-col cols="4">
        <span>{{ form.gender }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">อายุ</p></v-col>
      <v-col cols="4">
        <span>{{ form.age }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ประเภท</p></v-col>
      <v-col cols="4">
        <span>{{ form.role }}</span>
      </v-col>
      <v-col cols="2"><p style="font-weight: 800">ใบประกอบวิชาชีพ</p></v-col>
      <v-col cols="4">
        <span>{{ form.license.name }}</span>
      </v-col>

      <v-col cols="2"><p style="font-weight: 800">สถานที่</p></v-col>
      <v-col cols="4">
        <span>{{ form.workingArea }}</span>
      </v-col>
      <v-col cols="2"
        ><p style="font-weight: 800" class="pt-5">activeFlag</p></v-col
      >
      <!-- <v-col cols="2"
        ><p style="font-weight: 800" >ทักษะ</p></v-col
      >
      <v-col cols="4" v-for="(i, index) in form.userSkills" :key="index">
        <span>{{ i.name }}</span>
      </v-col> -->
      <v-col cols="4">
        <v-switch v-model="form.activeFlag" label="ใช้งาน"></v-switch>
      </v-col>
      <!-- <v-col cols="2"><p style="font-weight:800">idCargImg</p></v-col>
      <v-col cols="4">
        idCargImg
      </v-col>
      <v-col cols="2"><p style="font-weight:800">idCargImg2</p></v-col>
      <v-col cols="4">
        idCargImg2
      </v-col>
      <v-col cols="2"><p style="font-weight:800">activeFlag</p></v-col>
      <v-col cols="4">
        activeFlag
      </v-col> -->
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      // username: "",
      // password: "",
      // firstname: "",
      // lastname: "",
      // telCode: "",
      // telNumber: "",
      // line: "",
      // gender: "",
      // age: "",
      // licenseNo: "",
      // school: "",
      // experience: "",
      // allergy: "",
      // minFee: "",
      // maxFee: "",
      // province: "",
      // lat: "",
      // lng: "",
      // schedule: "",
      // role: "",
      // isPro: "",
      form: {
        activeFlag: "",
        age: "",
        allergy: "",
        count: 1,
        createdAt: "",
        education: "",
        educationId: "",
        educationImg: "",
        email: "",
        experience: "",
        feeCurrency: "",
        feeCurrencyId: "",
        feeFrequency: "",
        feeFrequencyId: "",
        firstName: "",
        gender: "",
        id: "",
        idCardImg: "",
        isPro: "",
        isPublic: "",
        job: "",
        jobId: "",
        lastName: "",
        lat: "",
        license: "",
        licenseId: "",
        licenseNo: "",
        line: "",
        lng: "",
        maxFee: "",
        minFee: "",
        password: "",
        profileImages: [],
        province: "",
        role: "",
        schedule: "",
        school: "",
        serviceType: "",
        serviceTypeId: "",
        telCode: "",
        telNumber: "",
        updatedAt: "",
        userRequest: "",
        userSkills: "",
        workingArea: "",
      },
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.form = userdata;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
  },
};
</script>